.af-view .af-class-section.af-class-footer.sticky {	
    position: sticky; z-index: 0; bottom: 0; padding-top: 80px; padding-bottom: 30px;	
  }

  .in-active .af-class-values-heading, .in-active.af-class-values-heading,	
  .in-active .af-class-year-text, .in-active.af-class-year-text { font-weight: 700; color: #000;}	
  
  .af-view .af-class-section.af-class-article-hero {
    z-index: 1 !important;
  }

  .af-class-transparent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .af-view .af-class-values-wrapper {
    padding: 40px 40px 40px 8vw !important;
  }

  .boxclose {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40px;
    right: 40px;
    color: #fff;
    text-decoration: none !important;
    cursor: pointer;
    z-index: 10000;
    text-indent: -9999px;
    background: #5225b5 url(./images/Icon_feather-plus.svg) no-repeat center center !important;
    background-size: 14px;
    transition: .4s ease-in-out;
  }

  @media screen and (max-width: 820px) {
    .boxclose {
      width: 45px;
      height: 45px;
      top: 20px;
    right: 20px;
  }
}

  .boxclose:hover { transform: rotate(90deg) scale(1.04); }

  .cursor-dot-outline {
    border-radius: 50%;
    position: absolute;
    pointer-events: none;
    z-index: 99999;
    transform: translate(-50%, -50%);
    transition: transform 150ms ease-in-out;
}

.cursor-dot-outline.play {
  background-image: url(./images/Icon-feather-play.svg);
}

.cursor::after {
  content: "";
  position: absolute;
  opacity: .5;
  top: -8px;
  left: -8px;
}

.af-view .af-class-timeline-dot {
  position: absolute;
  left: -9px;
  top: 0px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #5225b5;
}

@media screen and (max-width: 479px) {
  .af-view .af-class-timeline-dot {
    left: -13px;
    top: -4px;
    width: 30px;
    height: 30px;
  }
}


.af-view .af-class-section.af-class-journey-section {
  height: 1000px;
  overflow: hidden;
} 

@media screen and (max-width: 991px) {
  .af-view .af-class-section.af-class-journey-section {
    height: 1500px;
  }
}


.af-view .af-class-time-line-marker {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1px;
  height: calc(160vh + 1px);
  margin-top: 0 !important;
  margin-right: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ff5046;
}

.af-view .af-class-time-line-marker:before { content: ''; position: absolute; height: 120px; width: 1px; background: #ff5046; left: 0; top: -120px;}

.af-view .af-class-time-line-marker:after { content: ''; position: absolute; height: 120px; width: 1px; background: #ff5046; left: 0; bottom: -120px;}

.af-view .af-class-purple-circle {
  position: absolute;
  left: -9px;
  top: 0px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #5225b5;
}
