@media (min-width:992px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd3\"] {
        opacity: 0;
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd0\"] {
        height: 100%;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64ccd8\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"07b040d3-b31d-fcc5-35fd-cf96bf64cccf\"] {
        opacity: 0;
    }
}

@media (max-width:991px) and (min-width:768px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {
        display: none;
    }
}

@media (max-width:767px) and (min-width:480px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {
        display: none;
    }
}

@media (max-width:479px) {
    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"a2a3a560-3238-4fbc-3318-7b96ddc93b4a\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"cb5f2404-208e-f18b-0ba2-f7f868bea546\"] {
        display: none;
    }

    html.w-mod-js:not(.w-mod-ix) [data-w-id=\"672c114a-54df-4d8b-4c99-657f1ee1e251\"] {
        display: none;
    }
}


body {
    overflow-x: hidden;
}

.w-slider-dot {
    margin: 0 12px;
    background-color: rgba(82, 37, 181, 0.30);
}

.w-slider-dot.w-active {
    background-color: rgba(82, 37, 181, 0.80);
}

.carousel-slider {
    width: 100%;
    height: 100%;
}

.carousel .control-dots {
    bottom: 15% !important;
}

.carousel .btn {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 999;
    height: 100%;
}

.carousel .btn button {
    background: transparent;
}

.carousel .btn.next {
    right: 0;
    top: 0;
}

@media only screen and (max-width: 768px) {
    .carousel .control-dots {
        bottom: 20vh !important;
        margin-left: -18px;
    }

    .carousel .control-dots .dot {
        width: 12px !important;
        height: 12px !important;
        box-shadow: none;
        z-index: 99999;
    }
}

.carousel .control-dots .dot {
    width: 15px !important;
    height: 15px !important;
    box-shadow: none;
    z-index: 99999;
}

.carousal-image-overlay {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 50vh;
    background: transparent linear-gradient(180deg, #FFFFFF00 0%, #0F0F0F00 0%, #000000C9 100%) 0% 0% no-repeat padding-box !important;
}

.af-view .af-class-time-line-marker:before {
    height: 240px;
    top: -240px;
}

.gallery {
    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    gap: 10px;
}

.gallery .image {
    background-color: #f0f0f0;
    width: 100%;
    object-fit: cover;
}

.gallery .column {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.large-img {
    height: 415px;
}

.small-img {
    height: 203px;
}

.medium-img {
    flex-grow: 1;
    height: 320px;
}

.gallery button {
    background-color: #6900EF;
    color: #fff !important;
    border: none;
    padding: 14px;
    border-radius: 27px;
    cursor: pointer;
    font-size: 23px;
    margin-top: 27px;
    width: 100%
}

@media (max-width: 768px) {
    .carousel-root .carousel-slider {
        margin-top: 20px;
    }
    .gallery {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .gallery .image {
        height: auto;
        width: 100%;
    }

    .gallery button {
        width: 100%;
    }
}